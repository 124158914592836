.nav {
    display: flex;
    justify-content: "space-between | left";
    align-items: center;
    height: 60px;
    padding: 0 16px;
    background-color: #333;
    color: lightgrey;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .menu {
    display: flex;
    gap: 16px;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .link {
    padding: 8px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    font-size: 1.5rem;
    font-weight: bold;
    color: lightblue;
  }
  
  .link:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ADD8E6;
  }
  
  .link:active {
    color: #87CEFA;
  }

  .logo {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logoImage {
    width: 80%;
    height: 80%;
  }
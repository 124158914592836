.locationsContainer {
    padding: 20px;
    border-radius: 5px;
}

.treeContainer {
    background-color: #353535;
    color: #fff; 
    padding: 20px;
    border-radius: 5px;
}

.actualTree {
    background-color: #1e1e1e;
    color: #62dbe7; 
    font-size: 110%;
    font-weight: bold;
}


  .container {
    background-color: #222;
    color: white;
  }
  
  .collapse .header {
    background-color: #222;
  }
  
  .content {
    background-color: #555;
    margin-bottom: 8px;
    padding: 12px;
    border-radius: 4px;
    color: white;
    font-weight: normal;
  }
  
  .active {
    background-color: #666;
    color: white;
  }

  .panelTitleText {
    color: white;
    font-weight: bold;
  }

  .lighterText {
    color: white;
    font-weight: normal;
  }
  
.tableContainer {
    margin: 16px;
    border: 1px solid #555;
    background-color: #333;
    color: white;
  }
  
  .tableContainer table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .tableContainer th,
  .tableContainer td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #555;
  }
  
  .tableContainer th {
    background-color: #444;
    font-weight: bold;
  }
  
  .tableContainer tr:nth-child(even) {
    background-color: #555;
  }
  
  .tableContainer a {
    color: rgb(119, 198, 225);
  }
  
  .tableContainer a:hover {
    color: #8585ca;
  }

  .tableContainer button {
    background-color: #5c4caf; /* Green */
    border: none;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .tableContainer button:hover {
    background-color: #3e8e41;
  }
.App {
  text-align: left;
  margin-left: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: #3399FF; /* Default link color */
  text-decoration: none; /* Remove underline */
  transition: color 0.2s ease-in-out; /* Transition effect for link color */
}

a:hover {
  color: #66CCFF; /* Lighter blue when hovered */
}

a:active {
  color: #0080FF; /* Light blue when clicked */
}
